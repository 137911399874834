.cart-margin {
  padding: 0 40px;
  padding-bottom: 80px;
  margin: 50px auto;

  .ant-switch-checked {
    background-color: #ff777f;
  }

  .ant-card {
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 2.33929px rgba(0, 0, 0, 0.05);
    width: 393px;
    border-radius: 10px;

    .ant-card-body {
      padding: 15px !important;
    }

    .ant-descriptions-item-container .ant-descriptions-item-content,
    .ant-descriptions-item-container .ant-descriptions-item-label {
      display: inline-flex;
      align-items: baseline;
      flex-direction: row-reverse;
    }

    .ant-descriptions-item-content {
      font-size: 17px;
      color: #616161;
    }

    .ant-descriptions-row > td {
      padding-bottom: 7px;
    }

    .ant-descriptions-title {
      border-bottom: 1.1px solid #616161;
      font-size: 20px;
      padding-bottom: 7px;
      font-weight: 700;
    }

    .ant-descriptions-item-label {
      font-size: 17px;
      color: #616161;
    }

    .ant-descriptions-item-label:after {
      display: none;
    }

    .ant-divider-horizontal {
      display: flex !important;
      clear: both !important;
      height: 5px !important;
      color: black !important;
      width: 100% !important;
      min-width: 100% !important;
      margin: 10px 0 !important;
      background-color: transparent !important;
    }
  }

  .color-btn {
    background-color: transparent !important;
    color: white;
    width: 100%;
    padding: 2px 0;
    height: auto;
    border: 1px solid white;
    box-shadow: 0px 0px 4.67856px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  .color-btn:hover {
    background-color: #ec092d !important;
  }

  .card-body {
    background-color: transparent;

    .ant-card-body {
      padding: 0 !important;
      border-radius: 10px !important;
    }
  }

  .shiping-btn {
    width: 100%;
    height: 55px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  .cart-card {
    .ant-card-body {
      padding: 20px !important;
    }

    .font-24 {
      font-size: 18px;
    }

    .ant-input-number {
      width: 63px;
      font-size: 20px;
      color: #ec092d;
    }

    .h-45 {
      height: 45px;
      width: 45px;
    }

    .background-radius {
      padding: 0px 20px;
      background: #d8000d;
      display: flex;
      align-items: center;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    // style={{padding:'13px 20px' , background:'#D8000D', display:'flex',   borderBottomLeftRadius: "10px",
    //   borderBottomRightRadius: "10px",
  }

  .cart-card-text {
    color: #616161;
  }

  .border-radius {
    border-radius: 5.63393px;
  }
}

.border-none-input {
  .ant-input {
    background-color: transparent !important;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 130px;
  background: #f9f9f9;
}

.modal {
  .ant-modal-header {
    background: linear-gradient(180deg, #0b0029 0%, #000015 100%) !important;
    border-radius: 0 !important;
  }

  .ant-modal-title {
    .ant-typography {
      color: white;
    }
  }

  .ant-modal-content {
    width: 432px !important;
    height: 500px;
    max-height: 600px !important;
    background-color: #f9f9f9 !important;
    margin: auto;

    .ant-modal-body {
      background-color: #f9f9f9;
      height: auto !important;
    }
  }

  .modal-card {
    background: #f9f9f9;
    box-shadow: 0px 0px 2.4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 10px 15px;
  }
}

.resposive-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  height: 161px;
  width: 182px;
  border-radius: 9px;
  padding: 20;

  //   borderRadius: 9,
  //   padding: 20,
  //   width: "182.16px"
}

.pay-now-modal {
  .ant-modal-content {
    height: 100% !important;
    padding: 20px 0;
    width: 100% !important;
    background: linear-gradient(180deg, #0a0028 0%, #000016 100%);
    border-radius: 20px;

    .paynow-text {
      margin: 25px 0 !important;
      color: white;
      font-weight: 400;
    }

    .playnow-btn {
      border: 1px solid #ffffff;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
      border-radius: 5px;
      color: white;
      width: au;
    }
  }
}

.start-quiz {
  .ant-modal-content {
    background: url("../assets/comman/bg1.png");
    background-repeat: no-repeat;
  }

  .startquiz-card {
    margin: 20px auto;
    background: #ffffff;
    border-radius: 15px;

    .pad-0 {
      background: #ffffff;
      border: 1px solid #e4e4e4;
      border-radius: 10px;

      .ant-card-body {
        padding: 20px 0px !important;
      }
    }

    .text {
      margin-top: 10px !important;
      color: #e70736;
      cursor: pointer;
    }

    .ant-card-body {
      padding: 10px 3rem !important;
    }

    .ant-card-head {
      height: 40px;
      background-color: rgba(255, 99, 108, 1);
      border-radius: 15px 15px 0px 0px;

      .ant-card-head-title {
        padding: 11px 0;
        text-align: center;
        color: white;
      }
    }

    .quiz-text {
      margin: 25px 0 !important;
      color: black;
      font-size: 13px;
      font-weight: 400;
    }
  }
}

.quiz-modal {
  .ant-modal-content {
    background: url("../assets/comman/bg1.png");
    padding: 10px 0;
    background-repeat: no-repeat;
  }

  .startquiz-card {
    margin: 20px auto;
    background: #ffffff;
    border-radius: 15px;
    padding-bottom: 10px;

    .sec-box {
      padding: 0 15px;

      #quiz-box {
        margin-bottom: 10px !important;
        border: 1px solid #616161;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
        width: 276px;
        margin: auto;
        border-radius: 5px;
        padding: 10px;
      }
    }

    .pad-0 {
      background: #ffffff;
      border: 1px solid #e4e4e4;
      border-radius: 10px;

      .ant-card-body {
        padding: 20px 0px !important;
      }
    }

    .text1 {
      margin-top: 20px !important;
      color: #e70736;
      cursor: pointer;
    }

    .ant-card-body {
      padding: 10px 3.1rem !important;
    }

    .ant-card-head {
      height: 40px;
      background-color: rgba(255, 99, 108, 1);
      border-radius: 15px 15px 0px 0px;

      .ant-card-head-title {
        padding: 11px 0;
        text-align: center;
        color: white;
      }
    }

    .quiz-text1 {
      margin: 25px 0 !important;
      padding: 10px;
      background: #f5f5f5;
      border-radius: 5px;
      color: black;
      font-size: 15px;
      font-weight: 400;
    }
  }
}

.px-20 {
  padding: 0 20px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
  border-bottom: 2.18452px dashed #c4c4c4 !important;
}

.my-15 {
  margin: 15px 0;
}

.w-100 {
  width: 100%;
}

.w-100px {
  width: 100px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.p-30 {
  padding: 30px;
}

.mt-15 {
  margin-top: 15px;
}

.font-15 {
  font-size: 15px !important;
}

.padding-100 {
  padding: 100px;
}

.min-height-50vh {
  min-height: 50vh;
}

.margin-y {
  margin: 27px 0 !important;
  margin-bottom: 14px !important;
  font-weight: bolder;
  font-size: 24.4137px;
}

.copy-text {
  padding: 10px;
  background: #eeeeee;
  font-size: 18px;

  a {
    color: #e70736;
  }

  a:hover {
    color: #e70736;
  }
}

.pt-12 {
  padding-top: 12px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 15px;
}
.mb-7 {
  margin-bottom: 7px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-25-15 {
  padding: 25px 12px;
}

.p-3 {
  padding: 3px;
}

.pl-15 {
  padding-left: 15px;
}

.px-25 {
  padding: 0 25px;
}

.padding-20-10 {
  padding: 20px 10px;
}

.p-2-15 {
  padding: 2px 15px;
}

.px-20 {
  padding: 0 20px;
}

.pl-29 {
  padding-left: 29px;
}

.pl-10 {
  padding-left: 10px;
}

.px-10 {
  padding: 0 5px !important;
}

.bg-img {
  padding: 20px 15px;
  background: url("../assets/comman/Group\ 539.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

@media screen and (max-width: 1300px) {
}

.small {
  font-size: 15px;
}

.self-pickup-card {
  margin-top: 5px;
  padding: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  text-align: justify;
  color: rgb(97, 97, 97);
  font-size: 10px;
}

.modal {
  .scroll-auto {
    max-height: 550px;
    overflow: auto;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -6px !important;
    margin-bottom: 5px !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: rgba(231, 7, 54, 1) !important;
}

.payment-modal {
  width: 400px !important;

  .ant-modal-header {
    background: linear-gradient(180deg, #0b0029 0%, #000015 100%) !important;
    border-radius: 0 !important;
  }
  .ant-modal-body {
    padding: 20px 25px !important;
  }

  .ant-modal-title {
    .ant-typography {
      color: white;
      letter-spacing: 1px;
    }
  }
}
