.register-modal > .ant-modal-content,
.ant-modal-header,
.ant-modal-footer {
    border-radius: 20px !important;
}
.register-modal > .ant-modal-content {
    width: 350px;
    margin: auto !important;
}
.ant-select .ant-select-in-form-item {
    border-radius: 50px !important;
}

.otp-modal > .ant-modal-content,
.ant-modal-header,
.ant-modal-footer {
    border-radius: 20px !important;
}
.otp-modal > .ant-modal-content {
    width: 400px;
    margin: auto !important;
}
.otp-input {
    border-bottom: 1px solid black;
    margin: auto;
    input {
        width: 3em !important;
        border: transparent !important;
    }
    input:focus-visible {
        outline: none;
        border-bottom: 1px solid black;
    }
}
