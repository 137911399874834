//-------------------- Responsive sizes -------------------- //
$large-device-width: 1400px; // Large devices (desktops, less than 1200px)
$medium-device-width: 991px; // Medium devices (tablets, less than 992px)
$small-device-width: 767px; // Small devices (landscape phones, less than 768px)
$extra-small-device-width: 575px; // Extra small devices (portrait phones, less than 576px)

//-------------------- Responsive --------------------//
@mixin desktop {
  @media (max-width: #{$large-device-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$medium-device-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$small-device-width}) {
    @content;
  }
}

@mixin extra-small-mobile {
  @media (max-width: #{$extra-small-device-width}) {
    @content;
  }
}

//-------------------- End Responsive --------------------//
@media (max-width: 620px) {
}

@media (min-width: 768px) and (max-width: 991px) {
 
}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
}

@media (max-width: 419px) {
}

@media (min-width: 768px) {
}

@media (max-width: 1550px) {
  //hero-section-start-here

  .hero-section .padding {
    padding-top: 85px !important;
    padding-left: 55px;
  }
  .hero-container {
    img {
      width: 100%;
    }

    .white {
      margin: 20px 0 !important;

      font-size: 15px !important;
    }

    .heading {
      font-size: 25px !important;
      line-height: 30px !important;
    }

    .detail-btn {
      margin-top: 10px !important;
      background: transparent;
      cursor: pointer;
      border: 1px solid white;
      color: white;
      padding: 10px 35px !important;
      border-radius: 5px;
      font-size: 15px !important;
    }
  }

  //hero-section-start-here

  // campaings starts

  .camapaigns-card {
    img {
      width: 100% !important;
    }

    .money-img {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .money-img img {
      width: 100%;
    }

    .win-heading {
      font-size: 30px !important;
    }

    .level4 {
      font-size: 13px !important;
    }

    .text4 {
      font-size: 13px !important;
    }

    .responsive-text {
      font-size: 11px !important;
    }

    .responsive-text-1 {
      font-size: 8px !important;
    }

    .calendar-image {
      width: 89% !important;
    }

    .share-image {
      width: 50% !important;
    }
  }

  // campaings ends

  // contact start here

  .contact-wrapper {
    width: 80% !important;
    margin: 75px auto;
    padding-bottom: 100px;
  }
  // contact ends here

  // cart section  starts

  .cart-margin {
    

    .resposive-image{
      display: flex;
      justify-content: center;
      // align-items: center;
      background: #f9f9f9;
       height: 130px;
       width: 130px;
       border-radius:9px ;
       padding: 20;
    }
  }
  .ant-card {
    width: 100%;
  }
  .shipping-btn {
    width: 100%;
  }
  .rep-img
  {
    width: 110px!important; 
    height: 110px !important;
  }
  .font-24{
    font-size: 19px !important;
  }
  .card-text-rep{
font-size: 15px !important;
  }

  .image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px !important;
    width: 110px !important;
  }
  // cart section ends

  // payment-cart starts 
  .payment-cart{
    .ant-card{
      width: 100% !important;

    }
    .text{
      font-size: 12.1px !important;
      padding-left: 10px !important;
    }
  }
  // payment-cart ends 
  
}
