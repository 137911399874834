.active-coupon {
    .ant-card {
        border-radius: 14px !important;
    }
    .ant-card-body {
        padding: 15px 30px !important;
    }
    .ant-divider-horizontal {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        border: 1px solid #616161;
        height: auto !important;
        width: 100% !important;
        background-color: #616161 !important;
    }
    .coupon-title {
        color: black;
        font-weight: bold;
        font-size: 12px;
    }
    .pink-text {
        color: #ea082e;
        font-size: 12px;
    }
    .left-center-radius {
        background: #f1f1f1;
        padding: 20px;
        border-radius: 23px;
        position: absolute;
        top: 40%;
        left: -5%;
    }
    .right-center-radius {
        background: #f1f1f1;
        padding: 20px;
        border-radius: 23px;
        position: absolute;
        top: 40%;
        right: -5%;
    }
}
