.product-wrapper-margin {
    margin: 50px auto !important;

    .ant-card-body {
        padding: 10px !important;
    }

    .ant-card {
        border-radius: 10px !important;
    }

    .card-padding {
        padding: 10px;
        border-radius: 10px !important;
    }

    .ant-btn-custom {
        border-radius: 10px !important;
        background: #f1f1f1;
        width: 50px !important;
    }

    .full-width-btn {
        width: 100% !important;
        border-radius: 10px !important;
    }

    .ant-divider-vertical {
        border: 1px solid #d9d9d9 !important;
        height: 100% !important;
        background-color: #d9d9d9 !important;
    }

    .col-background {
        background: #f1f1f1 !important;
        border-radius: 10px !important;
    }
}

.color-progress-text {
    color: #ea082e;
    // font-size: 10px;
}

.progress-wrapper {
    padding: 10px !important;
    font-size: 12px !important;
}

.min-height-top {
    max-height: 100px !important;
}

.background-color-gray {
    background: #f1f1f1;
}

.gray-25 {
    color: #616161;
    font-size: 22px;
}

.gray-16 {
    color: #616161;
    font-size: 13px;
}

.pink-25 {
    color: #ea082e;
    font-size: 22px;
}

.product-image-container {
    width: 200px;
    height: 180px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-image-padding {
   padding: 20px;
}
.product-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.min-height-top-col {
    min-height: 80px;
}