// Top Header starts here

.top-header {
  background-color: #060021;
  padding: 5px 0;

  .container {
    width: 75%;
    margin: auto;
    color: white !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .email-text {
      color: #ea082e;
      font-weight: 400;
    }

    h3 {
      font-size: 15px;
      margin-top: 0;
      margin-bottom: 0 !important;
      color: white;
    }
  }
}

// Top header ends here

// Main Header starts here

.header-container {
  background-color: #f9f9f9;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  // position: sticky;
  // top: 0%;
  //   background-color: rgb(58, 54, 54);

  .main-header {
    width: 80%;
    position: sticky;
    top: 0;
    margin: auto;
    color: white !important;
    display: flex;
    padding: 3px 0px;
    justify-content: space-between;
    align-items: center;

    .menu ul {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .menu ul li {
      list-style: none;
      padding: 0 20px;
      font-size: 17px;
    }

    .menu ul li a {
      // letter-spacing:   1px;
      font-family: "Lexend";

      transition: 0.4s;
      color: #000;
      font-weight: bold;
    }
    .menu ul li a:hover {
      color: #ea082e;
    }

    .cart h3 {
      font-size: 15px;
      font-weight: bold;
    }

    .cart img {
      margin-right: 8px;
    }
  }
}

@media (max-width: 1550px) {
  .small-img {
    width: 20px !important;
  }
}

// @include desktop {
//   font-size: 24px;

// }
