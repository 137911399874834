.my-order {
  input {
    border-radius: 10px !important;
  }

  .ant-card {
    border-radius: 14px !important;
  }

  .ant-card-body {
    padding: 15px !important;
  }

  .ant-divider-horizontal {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    border: 1px solid transparent;
  }

  .order-filter {
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    border-radius: 10px;
  }

  .image-background {
    background-color: #f9f9f9;
    text-align: center;
    padding: 10px;
  }

  .order-title {
    color: black;
    font-weight: bold;
  }

  .order-description {
    color: #616161;
  }

  .order-price {
    color: #e70736;
  }

  .order-sub-description {
    color: black;
    margin-top: 5px;
  }

  .pink-text {
    color: #ea082e;
  }

  .cancle-icon {
    position: absolute;
    right: -7px;
    top: -14px;
    width: 35px;
    height: 35px;
    background: #e70736;
    border-radius: 0px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
  }
}

.order-list-overflow {
  margin-top: 10px;
  padding: 0;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

// .order-list-overflow::-webkit-scrollbar {
//     display: none;
// }

.order-list-overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.order-list-overflow::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
  margin-left: 15px !important;
}

.order-list-overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.button-play-now {
  background: #e70736;
  border-radius: 3px;
  color: white;
  border: 1px solid #e70736;
}

.custom-devider {
  min-width: 98% !important;
  margin: auto !important;
  height: 2px !important;
  background-color: #ededed !important;
}

.min-height {
  .ant-card-body {
    min-height: 330px !important;
  }
}

.order-filter {
  background-color: #f9f9f9;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.order-divider {
  border: 0.5px solid #616161 !important;
  height: 1px !important;
  width: 100% !important;
  background-color: #616161 !important;
}

.color-main {
  .ant-picker-range > .ant-picker-active-bar {
    background-color: #e70736 !important;
  }
}
.ant-picker-cell-in-view
  > .ant-picker-cell-range-start
  > .ant-picker-cell-inner {
  background-color: #e70736;
}
.ant-picker-header-view > button:hover {
  color: #e70736 !important;
}
