@import url("https://fonts.googleapis.com/css2?family=Baloo+2&family=Hubballi&family=Nunito:wght@200&family=Roboto:ital,wght@1,100;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");
@import "responsive";

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

* {
  margin-top: 0;
  margin-bottom: 0 !important;
  font-family: "Lexend";
  font-style: normal;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  background: #f1f1f1;
}

/* Disable hover effect */
// .hover-false {
//   color: black !important;
// }
// .hover-false:focus,
// .hover-false:hover {
//   background-color: inherit !important;
//   color: inherit !important;
//   border: 2px solid #d9d9d9 !important;
// }

/* Custom hover effect */
.ant-btn:focus,
.ant-btn:hover:not(.hover-false) {
  color: #fff;
  border-color: #e70736 !important;
  background: #e70736;
}

/* Border color for .hover-false */
// .hover-false {
//   border-color: #d9d9d9 !important;
// }
/* Custom hover effect */

.disabled-btn1:disabled {
  border-color: #d9d9d9 !important;
  background-color: transparent;
  color: black !important;
}

.disabled-btn1:hover {
  background-color: transparent !important;
  border-color: #d9d9d9 !important;
}

.disabled-btn1 {
  border-color: #d9d9d9 !important;
  background-color: transparent;
  color: black !important;
}

.flex {
  .disabled-btn:disabled {
    border-color: #d9d9d9 !important;
    background-color: transparent;
    color: black !important;
  }
}

.ant-input-number-input {
  text-align: center;
}

input,
.ant-input-number,
.ant-picker,
.ant-select-selector {
  border: none !important;
  border-radius: 5px !important;
}

.ant-input-number-focused {
  box-shadow: none !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

input:focus,
input:hover,
.ant-input-number:focus .ant-picker:focus,
.ant-picker:hover,
.ant-picker-focused,
.ant-select:hover,
.ant-select:focus,
.ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.ant-select-item-option-selected {
  background-color: #ffc6cf !important;
}

.ant-radio-checked .ant-radio-inner {
  background: linear-gradient(180deg, #d20358 0%, #ec092d 100%) !important;
  border: none !important;
  box-shadow: none !important;
  height: 20px !important;
  width: 20px !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: white !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: red !important;
}

.ant-radio-inner {
  height: 20px !important;
  width: 20px !important;
}

.container {
  // @include desktop {
  //   width: 100%;
  // }
  // ;
  width: 80%;
  margin: auto;
}

.title::before {
  position: absolute;
  content: "";
  top: 107%;
  height: 4px;
  width: 7%;
  background-color: red;
}

.width::before {
  width: 4% !important;
}

.text-line::after {
  content: "";
  display: block;
  width: 8%;
  padding-top: 3px;
  border-bottom: 4px solid #ea0830;
}

.ant-switch-checked {
  background: #e70736 !important;
}

.whites-btn:hover {
  color: white !important;
}

.button-comman {
  background: transparent;
  border-radius: 5px;
  text-align: center;
  border: 1px solid black;
  min-width: 30%;
  color: black;
}

.border-input {
  border: 1px solid black !important;
  border-color: black !important;
  border-radius: 5px !important;
}

.border-input:hover,
.border-input:focus {
  border: 1px solid black !important;
  border-color: black !important;
  border-radius: 5px !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover {
  border-color: black !important;
  box-shadow: none !important;
  border-right-width: 0px !important;
  border: 1px solid black !important;
}

.link-color {
  color: #e70736 !important;
}

.ant-btn:focus,
.ant-btn:hover {
  color: #fff;
  border-color: #e70736 !important;
  background: #e70736;
}

.coupon-background {
  margin-top: 5px;
  text-align: center;
  background: #e70736;
  box-shadow: 0px 0px 3.10606px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.commans-display-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.gray-color-border-bg {
  background-color: #f9f9f9;
}

.activelink a {
  pointer-events: visible !important;
  cursor: pointer !important;
}

#non-active a {
  pointer-events: none;
  cursor: default;
}

#non-active .unclick-div {
  pointer-events: none;
  cursor: default;
}

.remove-focus:focus,
.remove-focus:hover {
  color: black !important;
  border-color: #d9d9d9 !important;
  background: transparent !important;
}

.remove-bg:focus,
.remove-bg:hover {
  color: #e70736 !important;
  border-color: transparent !important;
  background: none !important;
}

.mobile-input {
  background: #ffffff !important;
  border: 0.5px solid #000000 !important;
  border-radius: 5px !important;
  padding: 10px 20px;
}

.mobile-input:hover,
.mobile-input:focus {
  background: #ffffff !important;
  border: 0.5px solid #000000 !important;
  border-radius: 5px !important;
  padding: 10px 20px;
}

.mobile-modal {
  .ant-modal-body {
    padding: 23px 30px !important;
    padding-bottom: 35px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.comman-container {
  @include desktop {
    width: 95% !important;
  }
}

.text-line {
  @include desktop {
    font-size: 20px !important;
  }
}

.text-line {
  font-size: 30px !important;
}

/* ToolTip Custom */
.ant-tooltip-arrow {
  bottom: 6px !important;
}

.ant-tooltip-inner {
  border-radius: 10px !important;
  box-shadow: 0 4px 11px rgb(86 86 86 / 30%) !important;
  color: #000000 !important;
}

.active {
  color: #ea082e !important;
}

.ant-spin-dot i {
  background-color: #e70736 !important;
}

.ant-pagination-item-active {
  border-color: #ea082e !important;
}

.ant-pagination-item-active a {
  color: #ea082e !important;
}

.ant-pagination-item:hover a {
  color: #ea082e !important;
}

.ant-pagination-next:hover .ant-pagination-item-link {
  color: #ea082e !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link {
  color: #ea082e !important;
}

.ant-btn,
.ant-radio {
  &::after {
    all: unset;
  }
}

.camapaigns-card:hover {
  transform: translate3D(0, -1px, 0) scale(1.03);
  box-shadow: 8px 28px 50px rgba(39, 44, 49, 0.07),
    1px 6px 12px rgba(39, 44, 49, 0.04);
  transition: all 0.4s ease;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.payment-card {
  border-radius: 8px;
  background-color: #f0ecf6 !important;
  margin-bottom: 15px !important;
  display: flex;

  .ant-card-body {
    padding: 15px 20px !important;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.maintenance {
  // background-image: url(https://demo.wpbeaveraddons.com/wp-content/uploads/2018/02/main-1.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

.maintenance {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.maintenance {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.maintenance_contain {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
}
.maintenance_contain img {
  width: auto;
  max-width: 100%;
}
.pp-infobox-title-prefix {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-top: 30px;
  text-align: center;
}

.pp-infobox-title-prefix {
  font-family: sans-serif;
}

.pp-infobox-title {
  color: #000000;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  display: block;
  word-break: break-word;
}

.pp-infobox-description {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.pp-infobox-description p {
  margin: 0;
}

.title-text.pp-primary-title {
  color: #000000;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4;
  margin-top: 50px;
  margin-bottom: 0px;
}

.pp-social-icon {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  line-height: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

.pp-social-icon a {
  display: inline-block;
  height: 40px;
  width: 40px;
}

.pp-social-icon a i {
  border-radius: 100px;
  font-size: 20px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
}

.pp-social-icon:nth-child(1) a i {
  color: #4b76bd;
}
.pp-social-icon:nth-child(1) a i {
  border: 2px solid #4b76bd;
}
.pp-social-icon:nth-child(2) a i {
  color: #00c6ff;
}
.pp-social-icon:nth-child(2) a i {
  border: 2px solid #00c6ff;
}
.pp-social-icon:nth-child(3) a i {
  color: #fb5245;
}
.pp-social-icon:nth-child(3) a i {
  border: 2px solid #fb5245;
}
.pp-social-icon:nth-child(4) a i {
  color: #158acb;
}
.pp-social-icon:nth-child(4) a i {
  border: 2px solid #158acb;
}

.pp-social-icons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
