.cart-address {
  .cart-card-text {
    color: #616161;
  }

  .font-24 {
    font-size: 24px;
  }

  .h-45 {
    height: 45px !important;
    width: 45px !important;
  }

  .ant-input-number {
    width: 63px;
    font-size: 20px;
    color: #ec092d;
  }

  .text-center {
    text-align: center;
  }
}

.modal-big {
  .ant-modal-header {
    background: linear-gradient(180deg, #0b0029 0%, #000015 100%);
    border-radius: 0 !important;

    .ant-typography {
      color: white;
      font-size: 24.34px;
    }
  }

  .ant-modal-content {
    width: 420px !important;

    background-color: #898989 !important;
    margin: auto;

    .ant-input-number-input {
      text-align: left;
      margin-bottom: 12px !important;
      height: 40px;
      border-radius: 6.08382px !important;
    }

    .ant-input {
      // width: 100%;
      margin-bottom: 12px !important;
      height: 50px;
      border-radius: 6.08382px !important;
    }

    .ant-modal-body {
      background-color: #f9f9f9;
      // height: 618px !ssimportant;
      padding: 0 !important;
    }

    .ant-modal-footer {
      background: white;
      border-radius: 0 !important;
    }

    .modal-card {
      height: 50px;
      display: flex;
      align-items: center;
      background: #ffffff;
      border: none;
      border-radius: 6.08382px;
    }

    .footer-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      padding: 0 20px;
      background-color: white;

      .address-btn {
        box-shadow: 0px 0px 4.86705px rgba(0, 0, 0, 0.25);
        border-radius: 243.353px;
        width: 347px;
        height: 47.45px;
      }
    }
  }
}

// Payment cart sec starts here

.payment-cart {
  padding: 0 10px !important;
  padding-bottom: 80px !important;
  margin: 40px auto !important;

  .ant-card {
    background: #ffffff;
    box-shadow: 0px 0px 2.33929px rgba(0, 0, 0, 0.05);
    border-radius: 11.6965px;
    width: 100%;

    .ant-input-number-input {
      width: auto;
      text-align: left;
      font-size: 20px;
      padding: 0;
    }

    .ant-picker {
      padding: 3px 0;
    }
  }

  .ant-card > .ant-card-body {
    padding: 10px 15px !important;
  }

  .red-card {
    margin-top: 10px;
    background-color: #d8000d;

    padding: 10px 15px !important;
  }

  .ant-checkbox-inner {
    width: 25px;
    height: 25px;
  }

  .text {
    color: black;
    font-size: 20px;
    padding-left: 20px;
  }
}

// Payment cart sec ends here

.modal-card {
  .radio-button {
    font-size: 15px;
    width: auto;
    border-radius: 0px !important;
  }

  .radio-button:hover {
    color: black;
  }

  .ant-radio-button-wrapper-checked:checked {
    background: #e70736 !important;

    border: none !important;
    box-shadow: none !important;
    height: 20px !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background-color: white !important;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #e70736 !important;
    box-shadow: none !important;
    border: none !important;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0px !important;
    border-left: 1px solid #d9d9d9 !important;
  }

  .ant-checkbox-input:focus .ant-checkbox-inner {
    border-left: 1px solid #d9d9d9 !important;
  }

  .ant-checkbox,
  .ant-checkbox:hover {
    border: 1px solid #d9d9d9 !important;

    box-shadow: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e70736;
    box-shadow: none !important;
    // border: 1px solid #d9d9d9 !important;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner:focus {
    box-shadow: none !important;
    background: #e70736 !important;
  }
}

.d-flex-center {
  display: flex;

  align-items: center;
}

.address-Type {
  border: 0.728px solid #616161;
  border-radius: 3.12px;
  margin-left: 10px;
  padding: 3px 15px;
  font-size: 15px;
  display: inline;
}

.form-basic {
  .ant-btn:hover{
    border: transparent !important;
  }


}


.form-basic {
  .ant-checkbox-checked{
color: white;
  }

  .ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #e70736 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #e70736 !important;
  }
}

.form-basic {
  .ant-checkbox-checked .ant-checkbox-inner,
  :hover {
    outline: none;
    box-shadow: none;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: white;
    background: #e70736;
    border: none !important;
    box-shadow: none !important;
  }

  .ant-checkbox {
    border-radius: none !important;
  }

  .ant-checkbox-checked:after {
    border: none !important;
    border-radius: none !important;
  }

  .ant-radio-button-wrapper:hover {
    color: black !important;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: none !important;
  }

  .ant-select-selector {
    padding: 9px 10px !important;
    height: auto !important;
    text-indent: 0px;
  }

  .ant-select-selection-search {
    padding: 5px 0 !important;
  }

  .ant-select-selection-search-input {
    padding: 21px 0px !important;
  }
}

.new {
  .ant-btn:hover {
    color: #000 !important;
    border-color: #fff !important;
    background: #fff !important;
  }
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}
.active-bg {
  border: 1px solid #ec092d;
}
