//Hero section starts here

.hero-section {
  overflow: auto;
  padding-bottom: 100px;
  background-repeat: no-repeat;

  .hero-container {
    width: 75%;
    margin: auto;
    color: white !important;
  }

  .save-btn {
    background-color: rgba(20, 20, 20, 0.8) !important;
  }

  .hero-midd {
    padding-top: 60px;
    // padding-left: 30px;
  }

  .white {
    // line-height: 50px;
    margin: 20px 0;
    font-size: 19px;
    color: white;
    letter-spacing: 1px;
  }

  .padding {
    padding-top: 150px;
    padding-left: 55px;
  }

  h2 {
    letter-spacing: 1px;
  }

  .heading {
    letter-spacing: 2px;
    font-family: "Lexend", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 35px;
    line-height: 47px;
  }

  .detail-btn {
    margin-top: 30px !important;
    background: transparent;
    border-radius: 5px;
    text-align: center;
    border: 1px solid white;
    min-width: 30%;
    padding: 2px 35px !important;
    color: white;
    height: auto;
    margin: auto;
  }
}

//Hero section Ends here

//    Closing Soon Starts here

.cs-container {
  width: 80%;
  margin: auto;
  padding: 2rem 0;
  padding-bottom: 50px !important;
}

.popup-card-btn {
  border: 1px solid #000000;
  border-radius: 5px;
  //color: black !important;
  min-width: 150px !important;
  height: auto;
  padding: 10px;
  font-weight: bold;
}

.popup-card-btn:hover {
  color: white !important;
}

.modal-detail {
  .ant-modal-close-x {
    display: none;
  }

  > .ant-modal-content {
    border-radius: 10px !important;
  }
}

// closing sooon slider

.closing-soon-slider {
  margin: 26px 0;

  .slick-track {
    width: 100% !important;
  }

  .slick-prev:hover {
    // display: none  !important;
    opacity: 0s;
    position: relative;
    right: 34px;
    display: block;
    width: 46px;
    bottom: 53px;
    background: red;
    left: 92%;
    height: 50px;
  }

  .slick-prev {
    position: relative;
    right: 34px;
    // display: none  !important;
    opacity: 0;
    width: 46px;
    bottom: 53px;
    // background: red;
    left: 92%;
    height: 50px;
  }

  .slick-next:hover {
    // display: none  !important;
    // opacity: 0s;
    position: relative;
    right: 34px;
    opacity: 0;
    width: 54px;
    background: red;
    bottom: 392px;
    left: 96%;
    height: 49px;
  }

  .slick-next {
    position: relative;
    right: 34px;
    opacity: 0;
    width: 54px;
    background: red;
    bottom: 358px;
    left: 96.4%;
    height: 49px;
  }

  .slider-card {
    background: #ffffff;
    border: none;
    border-top: 4px solid #d10358;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .card-center {
    text-align: center;
    //display: flex !important;
    //justify-content: center;
    margin-right: 20px;
    //align-items: center;
  }
}

// Closing Soon Ends Here

/*Closing soon new Start*/
.container-closing-soon {
  width: 80%;
  margin: auto;
  padding: 3rem 0;
  padding-bottom: 0px !important;

  .slick-list {
    padding: 20px 0px !important;
  }

  .slider-card {
    width: 230px;
    //min-height: 330px !important;
    padding: 5px;
    //margin: 20px;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 5%);
    border-radius: 10px;
    border-top: 4px solid #d10358;
  }

  .ant-card-body {
    padding: 15px !important;
    text-align: center;
  }

  .ant-card-cover {
    text-align: center;
  }

  .bg-gray-closing {
    padding: 5px !important;
    background: #f9f9f9;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  // .card-center {
  //   width: max-content !important;
  // }
  // .slick-slide {
  //   width: max-content !important;
  // }
  // .slick-active {
  //   width: max-content !important;
  // }
  // .slick-current {
  //   width: max-content !important;
  // }
}

.closing-soon-less-four {
  .slick-track {
    width: 100% !important;
    display: flex !important;
  }

  .slick-slide {
    width: auto !important;
  }

  .card-center {
    width: 254px !important;
  }

  .slick-list {
    padding: 20px 0px !important;
  }
}

/*Closing soon new End*/

// Campaigns starts here

.camapaigns-card {
  margin-bottom: 20px !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid #f0e7e7;

  .padding-bg {
    padding: 10px;
  }

  .padding-bg-border {
    border: 1px solid #c1c1c1;
    border-radius: 10px;
  }

  .color-pink {
    font-size: 17px;
    color: #d8000d;
  }

  .ant-card-body {
    padding: 15px 20px !important;
  }

  .win-heading {
    font-size: 40px;
    font-weight: 700;
    color: #d8000d;
  }

  .level4 {
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: bold !important;
    letter-spacing: 1px;
    margin-top: 0 !important;
    font-family: "Lexend";
  }

  .text4 {
    margin-top: 10px !important;
    font-family: "Lexend";
    font-size: 17px;
  }

  .text4-inner {
    color: #d8000d;
    margin-left: 10px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .add-card-btn {
    border: 1px solid #000000;
    border-radius: 5px;
    color: black !important;
    min-width: 130px !important;
    height: auto;
    padding: 10px 28%;
    font-weight: bold;
  }

  .add-card-btn:hover {
    color: white !important;
  }
}

// Campaigns Ends here

// Sold Out section starts here

.sold-out-container {
  width: 100%;
  margin: auto;
  padding: 2.2rem 0;
  padding-bottom: 50px !important;
  background: linear-gradient(180deg, #d10359 0%, #ec092d 100%);

  .sold-out-title {
    width: 80%;
    margin: auto;
  }

  .sold-title {
    font-size: 24px;
    font-family: "Lexend";
    letter-spacing: 3px;
    font-style: normal;
    color: white;

    @include desktop {
      font-size: 24px;
    }
  }

  .font-16 {
    font-size: 16px;

    @include desktop {
      font-size: 14px;
    }
  }

  .sold-title::before {
    position: absolute;
    content: "";
    top: 107%;
    height: 4px;
    width: 7%;
    background-color: black;
  }

  .sold-out-slider-card {
    @include desktop {
      min-width: 300px;
      max-width: 150px;
    }

    border: none;
    display: flex;
    margin: 0 5px;
    padding: 10px 0;
    justify-content: center;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    min-width: 400px;
    max-width: 400px;

    // align-items: center;
    .gray-image {
      filter: grayscale(100%);
      opacity: 0.7;
    }

    .sold-out-image {
      @include desktop {
        left: 69px;
      }

      position: absolute;
      top: -150px;
      left: 90px;
    }

    .ant-card-cover {
      text-align: center;
    }

    .ant-card-body {
      padding: 0px 25px !important;
    }

    .level4 {
      @include desktop {
        font-size: 18px;
      }

      font-size: 25px;
    }
  }

  .text-left {
    text-align: left !important;
  }

  .sold-out-slider {
    margin: 60px 0;
    padding: 0 20px;
  }

  .sold-out-slider-less-four {
    .slick-track {
      width: 100% !important;
      display: flex !important;
    }

    .slick-slide {
      width: auto !important;
    }

    .card-center {
      width: 400px !important;
    }
  }

  .card-center {
    text-align: center;
    display: flex !important;
    justify-content: center;
    margin-right: 20px;
    align-items: center;
  }
}

// Sold Out section ends here

// Grandealz Products starts here

.modal-product-detail {
  .ant-modal-close {
    color: black !important;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .Modal-center {
    display: flex;
    height: 400px;
    max-height: 450px;
    justify-content: center;
    align-items: center;
  }
}

.product-container {
  background: #f1f1f1;

  .product-inner-container {
    width: 80%;
    margin: auto;
    padding: 3rem 0;
    padding-bottom: 50px !important;
  }
}

.product-slider {
  padding-top: 50px;
}

.product-less-four {
  .slick-track {
    width: 100% !important;
    display: flex !important;
  }

  .slick-slide {
    width: auto !important;
  }

  .card-center {
    width: 254px !important;
  }
}

.product-card {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  border: none;
  margin: 5px 0 !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;

  .box {
    background-color: #f1f1f1;
    margin-top: 10px;
    width: 207px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 75% !important;
    }
  }

  .ant-typography {
    margin-top: 0 !important;

    font-weight: 400;
  }
}

.product-card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  transform: translate3D(0, -1px, 0) scale(1.03);
  transition: all 0.4s ease;
}

.slider-card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  transform: translate3D(0, -1px, 0) scale(1.03);
  transition: all 0.4s ease;
}

.product-page-card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  transform: translate3D(0, -1px, 0) scale(1.03);
  transition: all 0.4s ease;
}

// Grandealz Products ends here

// Draws starts here

.draws-container {
  width: 75%;
  margin: 49px auto;
  margin-bottom: 20px !important;

  .slick-slide {
    width: 140px !important;
  }

  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 5.5% !important;
    min-width: 0% !important;
    height: 4px;
    background-color: #ea0830;
    margin: 5px 0 !important;
  }
}

.product-page-container {
  width: 75%;
  margin: 60px auto;
  margin-bottom: 70px !important;
  min-height: 50vh;

  .product-page-card {
    padding: 10px;
    box-shadow: 0px 0px 3.43158px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    @include desktop {
      .ant-card-cover {
        display: flex;
        margin: auto;
      }
    }
  }

  .ant-card-cover {
    text-align: center;
    // background: #f1f1f1;
    // padding: 10px;
  }

  .ant-card-body {
    display: flex;
    justify-content: center;
  }
}

.slider-title {
  margin-bottom: 10px !important;
}

.ant-card-body {
  padding: 5px !important;
}

.ant-segmented-item {
  transition: linear-gradient(
    92.07deg,
    #d1035a 0.77%,
    #ec092d 88.1%
  ) !important;
  padding: 8px 0;
}

.ant-segmented-item-selected:hover {
  color: white;
}

.ant-segmented-item-selected {
  border-radius: 5px;
  color: white;
  background: linear-gradient(
    92.07deg,
    #d1035a 0.77%,
    #ec092d 88.1%
  ) !important;
}

.ant-segmented-thumb {
  border-radius: 5px;
  color: white;
  background: #e70736;
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 5.5% !important;
  min-width: 0% !important;
  height: 4px;
  background-color: #ea0830;
  margin: 5px 0 !important;
}

.draw-wrapper {
  width: 80%;
  margin: 50px auto !important;

  .congratulation-text {
    font-size: 22px;
    color: #e70736;
    font-weight: bold;
    margin: 10px 0px !important;
  }

  .winner-name-draw-title {
    font-size: 18px;
    font-weight: bold;
  }

  .winner-title {
    font-size: 18px;
  }

  .ticket {
    margin-top: 10px;
  }
}

.winner-card {
  box-shadow: 0px 0px 2.8131px rgba(0, 0, 0, 0.05) !important;
  border-radius: 7.03276px !important;
}

.Draws-modal {
  .ant-select-selector {
    border: 1px solid black !important;
    box-shadow: none !important;
    height: 40px !important;
    align-items: center !important;
  }

  .ant-modal-header {
    box-shadow: none;
    border: none;
    padding: 12px 20px;
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-content {
    box-shadow: 0px 0px 5.32459px rgba(0, 0, 0, 0.15);
    border-radius: 13.3115px;
    width: 479.21px !important;

    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled
      ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
      color: black;
      border: 1px solid #000;
    }

    .ant-input-affix-wrapper {
      color: black;
      border-radius: 6px;
      border: 1px solid #000;

      .ant-input {
        padding: 4px 0;
        text-indent: 4px;
      }
    }

    .ant-input-affix-wrapper-status-error .ant-input-prefix {
      color: black !important;
    }

    .ant-picker {
      border: 1px solid #000 !important;
      width: 100%;
    }

    .ant-picker-input {
      padding: 4px 5px;
    }

    .ant-input-prefix {
      margin-left: 4px;
    }
  }
}

.draw-main-sec {
  margin-top: 50px;
}

.ant-modal-body {
  padding: 12px 15px !important;
}

.ant-modal-close {
  color: white !important;
}

.ant-modal {
  width: 500px !important;
}

.reset-text {
  font-size: 1.4em;
  color: #e70736 !important;
}

.filter-modal > .ant-modal-content {
  width: 430px !important;
}

// Draws ends here

// FAQ starts here

.text-title {
  font-size: 40px;
  font-weight: 600;
}

.wrapper {
  margin: 75px auto;
  padding-left: 10px !important;
  width: 80%;
  margin-bottom: 83px !important;

  .ant-card {
    background-color: transparent !important;

    .ant-card-head {
      padding: 0 !important;
    }

    .faq-card-text {
      @include desktop {
        font-size: 18px;
      }

      font-weight: 500;
      font-size: 25px;
      color: #616161;
    }

    p {
      @include desktop {
        font-size: 13px;
      }

      font-family: "Lexend";
      font-style: normal;
      font-weight: 400;
      text-align: justify;
      font-size: 16px;
      color: #616161;
    }
  }

  .faq-text {
    @include desktop {
      font-size: 22px;
    }

    font-weight: 600;
    font-size: 25px;
    color: #616161;
  }
}

// FAQ ends here

// Contact Us Starts here

.contact-wrapper {
  width: 67%;
  margin: 75px auto;
  padding-bottom: 100px;

  .lorem-text {
    @include desktop {
      font-size: 18px;
    }
  }

  .test-iframe {
    @include desktop {
      height: 180px !important;
    }
  }

  textarea {
    @include desktop {
      // height: 45px;
      font-size: 15px;
    }

    border: none !important;
    box-shadow: none !important;
    font-size: 20px;
    width: 90%;
    text-indent: 7px;
  }

  .button-comman {
    @include desktop {
      width: 200px;
      height: 40px;
      font-size: 15px;
    }

    font-size: 20px;
    width: 350px;
    height: 55px;
    font-weight: 600;
    margin-top: 35px !important;
  }

  input {
    @include desktop {
      height: 64px;
      font-size: 20px;
    }

    font-size: 20px;
    // padding: 15px 12px;
    height: 64px;
    text-indent: 7px;
    width: 90%;
  }

  .map-text {
    @include desktop {
      font-size: 18px;
    }

    font-size: 25px;
    font-weight: 550 !important;
  }

  .ant-divider {
    border-top: 1px solid #000;
  }

  .ant-descriptions-item-label:after {
    content: ":-" !important;
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }

  .ant-descriptions-item-container > .ant-descriptions-item-content {
    @include desktop {
      font-size: 16px;
    }

    font-size: 20px;
    text-align: justify;
  }

  .ant-descriptions-item-container > .ant-descriptions-item-label {
    @include desktop {
      font-size: 18px;
    }

    font-size: 23px;
    font-weight: 600;
  }

  .ant-divider-horizontal {
    display: flex !important;
    clear: both !important;
    height: 5px !important;
    color: black !important;
    width: 100% !important;
    min-width: 100% !important;
    margin: 10px 0 !important;
    background-color: transparent !important;
  }
}

// Contact Us ends here

//How it Works starts here

.video-main-screen {
  margin: 75px auto;
  margin-bottom: 85px !important;
  padding-left: 10px !important;
  width: 80%;

  .video-wrapper {
    position: relative;
    display: flex;
    // width: 100%;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 535px;
    background: url("../assets/comman/video.png");
    align-items: center;
    justify-content: center;

    .text-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .video-thumbnail-image {
      cursor: pointer;
    }
  }
}

.my-20 {
  margin-bottom: 20px !important;
}

.padding-bg {
  padding: 10px;
  background: #f9f9f9;
  border-radius: 5px;
}

//How it Works Ends here

.bg {
  background-color: #fff;
  padding: 15px;
  // border-radius: 6px;
}

.bg img {
  width: 100%;
  border-radius: 6px;
}

.font10 {
  @include desktop {
    font-size: 10px;
  }

  font-size: 15px;
}

.text-draw {
  font-size: 15px;

  @include desktop {
    font-size: 12px;
    text-align: center;
  }
}

@media (max-width: 1550px) {
  .sold-out-slider-card {
    max-width: 350px !important;
    min-width: 150px !important;
  }

  .footer-heading {
    font-size: 15px !important;
  }

  .footer-container {
    li a {
      font-size: 13px !important;
    }
  }

  .test {
    font-size: 18px !important;
  }

  .text-draw {
    font-size: 12px !important;
  }

  .footer-container {
    .text {
      font-size: 13px !important;
    }
  }

  .font-20 {
    font-size: 13px;
  }
}

.font-20 {
  font-size: 16px !important;
  padding: 10px 20px !important;
  font-weight: 400 !important;
}

.color-white {
  color: white !important;
}

/*Float Button*/

.flBtnCntr,
.flBtnBox {
  cursor: pointer;
}

.flBtnBox.small {
  animation: showBtn 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  transform: scale(0);
  margin: 0 5px;
  padding: 5px;
}

.flBtnBox.small:nth-child(2) {
  animation-delay: 200ms;
}

.flBtnBox.small:nth-child(3) {
  animation-delay: 400ms;
}

@keyframes showBtn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.flBtns {
  position: absolute;
  left: -22%;
  top: -54px;
  bottom: 0;
  display: none;
}

.flBtnCntr:hover .flBtns {
  display: inline-flex;
}

/*Float Button End*/

@media (max-width: 1550px) {
  .sold-out-image {
    left: 69px !important;
  }
}

.pad-0 {
  .ant-card .ant-descriptions-row > td {
    padding-bottom: 0 !important;
  }
}

.font-10 {
  .ant-descriptions-item-label {
    font-size: 13px !important;
  }
}
