.slider-wrapper {
  position: relative;
  // height: calc(100vh-20%) !important; 
  height: calc(100vh - 180px);
  overflow: hidden;


  .previousButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    left: 5px;
    background: url('../assets/comman/lefticon.png') no-repeat center center / 40px;
    color: white !important;
    width: 100px;
    height: 100px;
    text-indent: -9999px;
    cursor: pointer;
  }

  .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: url('../assets/comman/righticon.png') no-repeat center center / 40px;
    color: white !important;
    width: 100px;
    right: 0;
    height: 100px;
    text-indent: -9999px;
    
    cursor: pointer;
  }

}

@media (max-width: 1500px) {


  .slider-wrapper {


    height: calc(100vh - 90px);
    // height: 200vh;
    overflow: hidden;
  }
}