.main-wrapper {
    height: auto;
    background: #f1f1f1;
    padding: 50px 0;
}

.site-card-border-less-wrapper {
    padding: 10px;
    background: #ffffff;
    border-radius: 15px;

    .card-margin {
        padding: 0px;
        background: #f9f9f9;
        border-radius: 10px;
        margin: 2px 0;

        .ant-card-body {
            padding: 12px;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 23px;
    }

    h3 {
        margin: 4px 0;
        font-weight: 400;
    }
}

.profile-pic {
    margin-top: 20px;
    border: 1px solid #0A0027;
    border-radius: 3.5px;
}

.card-image {
    background: #f9f9f9;
    border-radius: 8px;
    text-align: center;
}

.member-detail {
    padding: 10px;
    background: #f9f9f9;
    border-radius: 8px;
}

.spacer {
    margin-top: 15px;
}

.button-member {
    background: #f9f9f9;
    border-radius: 5px;
    text-align: center;
    height: fit-content;
    font-size: 14px;
    float: right;
}

.button-logout {
    background: #ffffff;
    border-radius: 5px;
    text-align: center;
    width: 40%;
}

.first-text-color {
    color: #e70736;
}

.first-divider {
    margin: 5px 0 !important;
}

.second-divider {
    margin: 5px 0 !important;
    border: 4px solid white;
    border-radius: 5px;
}

.content-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 150px;
    left: 0;
    top: 55%;
    bottom: 31%;
    right: 0;
    opacity: 1;
    transition: all 0.4s ease-in-out 0s;
    z-index: 1;
    margin: auto;
    border-radius: 3px;
    background-image: url("../assets/profilePage/edit.png");
    background-repeat: no-repeat;
    background-position: center;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #e70736 !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: #e70736 !important;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #e70736 !important;
}

.font-bold {
    font-weight: 600 !important;
    letter-spacing: 1px;
}

